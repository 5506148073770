import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Hakkımızda | Striker's Edge Futbol Kulübü Hakkında
			</title>
			<meta name={"description"} content={"Striker's Edge Futbol Kulübü'nde hikayemiz, her pasın, çalımın ve golün spora olan bağlılığımızı anlattığı sahada yazılıyor. "} />
			<meta property={"og:title"} content={"Hakkımızda | Striker's Edge Futbol Kulübü Hakkında"} />
			<meta property={"og:description"} content={"Striker's Edge Futbol Kulübü'nde hikayemiz, her pasın, çalımın ve golün spora olan bağlılığımızı anlattığı sahada yazılıyor. "} />
			<meta property={"og:image"} content={"https://uhanighets.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://uhanighets.com/img/919510.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uhanighets.com/img/919510.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uhanighets.com/img/919510.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uhanighets.com/img/919510.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://uhanighets.com/img/919510.png"} />
			<meta name={"msapplication-TileImage"} content={"https://uhanighets.com/img/919510.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://uhanighets.com/img/5.jpg) 0% 0%/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
					Hakkımızda
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					Striker's Edge Futbol Kulübü'nde hikayemiz, her pasın, çalımın ve golün spora olan bağlılığımızı anlattığı sahada yazılıyor. Futbol tutkusundan doğan kulübümüz, yetenek, hırs ve dostluğun bir araya geldiği bir topluluğa dönüştü.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Schedule-8" background="#f3f1f1">
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px 30px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="30px"
				md-grid-gap="32px"
			/>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Bizim Felsefemiz
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Futbolun ilham verme, birleştirme ve kişisel gelişimi teşvik etme gücüne inanıyoruz. Yaklaşımımız, yenilikçi eğitim metodolojilerine bağlılıkla birlikte oyunun geleneklerine derin bir saygıya dayanmaktadır. Striker's Edge'de sadece oyuncu yetiştirmiyoruz - hem saha içinde hem de saha dışında takım çalışması ve sportmenlik ruhunu taşıyan çok yönlü bireyler yetiştiriyoruz.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Yolculuğumuz
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Striker's Edge, tek bir takımla mütevazı başlangıcından itibaren çeşitli yaş grupları ve beceri seviyelerine sahip saygın bir kulüp haline geldi. Yolculuğumuz, faaliyetlerimizin her alanında mükemmelliğe olan bağlılığımızı yansıtan zaferler, zorluklar ve sürekli öğrenme ile işaretlenmiştir.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Toplumsal Etki
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Striker's Edge bir futbol kulübünden çok daha fazlasıdır - toplumda bir yol göstericidir. Futbol merceğinden sağlık, eğitim ve sosyal sorumluluğu teşvik eden toplumsal etkinliklere, sosyal yardım programlarına ve girişimlere aktif olarak katılıyoruz.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uhanighets.com/img/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Gelecek Vizyonu
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Vizyonumuz, spora katkımız ve üyelerimizin yaşamları üzerindeki olumlu etkimizle tanınan, futbolda mükemmellikte lider olmaktır. Gelecek vadeden futbolcuların gelişebileceği ve oyuna duyulan sevginin her geçen sezon daha da güçlendiği bir ortam yaratmaya devam etmeyi amaçlıyoruz.
				</Text>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});